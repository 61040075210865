exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cardapio-de-natal-js": () => import("./../../../src/pages/cardapio-de-natal.js" /* webpackChunkName: "component---src-pages-cardapio-de-natal-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lojas-e-cardapios-js": () => import("./../../../src/pages/lojas-e-cardapios.js" /* webpackChunkName: "component---src-pages-lojas-e-cardapios-js" */),
  "component---src-pages-nossa-historia-js": () => import("./../../../src/pages/nossa-historia.js" /* webpackChunkName: "component---src-pages-nossa-historia-js" */),
  "component---src-pages-obrigado-js": () => import("./../../../src/pages/obrigado.js" /* webpackChunkName: "component---src-pages-obrigado-js" */),
  "component---src-pages-viva-essa-experiencia-js": () => import("./../../../src/pages/viva-essa-experiencia.js" /* webpackChunkName: "component---src-pages-viva-essa-experiencia-js" */)
}

